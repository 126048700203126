import React from 'react';
import error from '../../../Assets/Global/error.jpg';
import './NotFound.css'

const NotFound = () => {
    return (
        <>
            <div className='container my-5'>
                <img className='error-img' src={error} alt=""/>
            </div>
        </>
    );
};

export default NotFound;