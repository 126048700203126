import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../Loading/Loading';
import BlogRow from './BlogRow';
import './Blog.css'

const Blog = () => {

    const { data: blogs, isLoading } = useQuery(["blogs"], () => fetch('/blog.json').then(res => res.json()))
    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <>
            <div className='common-title'>
                <div className='container'>
                    <h2>Blog</h2>
                </div>
            </div>
            <div className='container row g-5  m-auto mb-5 p-0'>
                {
                    blogs?.map((blog) => <BlogRow
                        key={blog.id}
                        blog={blog}
                    >
                    </BlogRow>)
                }
            </div>
        </>
    );
};

export default Blog;