import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BsPencilSquare, BsEraserFill } from "react-icons/bs";
import { TbListDetails } from "react-icons/tb";
import { VscRequestChanges } from "react-icons/vsc";
import { useAuthState } from "react-firebase-hooks/auth";
import useAdmin from "../../../../../Hooks/useAdmin/useAdmin";
import auth from "../../../../../firebase.init";
import { toast } from "react-toastify";
import './GetProblemsRow.css';

const GetProblemsRow = ({ getOneProblem, index }) => {
    const [user] = useAuthState(auth)
    const [admin] = useAdmin(user)
    const { id } = useParams()
    const { problemsName, problemsStatus, _id, companyName, leaderName, request } = getOneProblem
    const navigate = useNavigate();
    const route = () => {
        navigate(`/updateStatus/${_id}`)
    }
    const details = () => {
        navigate(`/problemsDetails/${_id}`)
    }
    const reqEdit = () => {
        fetch(`https://cutsew-server.onrender.com/requestEdit/${_id}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id })
        }).then(res => res.json())
            .then(data => {
                if (data) {
                    toast('Request Success');
                } else {
                    toast.error('Request Unsuccess')
                }
            })
    }

    const reqRemove = () => {
        fetch(`https://cutsew-server.onrender.com/requestRemove/${_id}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify({ id })
        }).then(res => res.json())
            .then(data => {
                if (data) {
                    toast('Request Remove');
                } else {
                    toast.error('Request Not Remove')
                }
            })
    }

    return (
        <>
            <tr>
                <td>{index}</td>
                {
                    admin &&
                    <td> {leaderName}</td>
                }
                <td> {companyName}</td>
                <td> {problemsName}</td>
                <td> {problemsStatus}</td>
                <td>
                    {
                        !admin && problemsStatus === "solved" &&
                        request == "" &&
                        <a title="Request to Edit" onClick={reqEdit} className="me-2 "><VscRequestChanges className="text-white" /></a>
                    }
                    {
                        !admin &&
                        request == "edit" &&
                        <a disabled onClick={reqEdit} className="me-2"><VscRequestChanges className="text-white" /></a>
                    }
                    {
                        problemsStatus === "pending" &&
                        <a title="Edit Status" onClick={route} className=" me-2"><BsPencilSquare className="text-white" /></a>
                    }

                    {
                        admin &&
                        problemsStatus === "solved" &&
                        <a title="Edit Status" onClick={route} className="me-2"><BsPencilSquare className="text-white" /></a>
                    }
                    {
                        admin &&
                        request == "edit" &&
                        <a title="Deny Request" onClick={reqRemove} className="me-2"><BsEraserFill className="text-white" /></a>
                    }
                    <a title="Details" onClick={details} className="me-2"><  TbListDetails className="text-white" /></a>
                </td>
            </tr>

        </>
    )
}
export default GetProblemsRow