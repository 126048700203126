import React, { useState } from "react"
import { Helmet } from "react-helmet";
import { useQuery } from "react-query"
import { useParams } from "react-router-dom";
import Loading from "../../../Loading/Loading";

const ProductDetails = ({show,handleClose, handleShow}) => {
    const { id } = useParams()
    const url = `https://cutsew-server.onrender.com/productDetails/${id}`
    const { data: product, isLoading } = useQuery(['product', id], () => fetch(url, {
    })
        .then(res => res.json())
    )
    if (isLoading) {
        return <Loading></Loading>
    }
    return (
      <>

        <div className="container">
            <h1>this is a   {product?._id}</h1>
            <h1>ProductPrice{product?.productPrice}</h1>
            <p>ProductDetails{product?.productDescription}</p>

            {product?.descriptions ?
                product?.descriptions?.map((l) => <li>{l.descriptions}</li>)
                :
                ''}
            {product?.descriptions2 ?
                product?.descriptions2?.map((d) => <li>{d.descriptions2}</li>)
                :
                ''}
        </div>
        
   
      </>
    )
}
export default ProductDetails