import React from "react"
import { useQuery } from "react-query"
import Loading from "../../../../Loading/Loading"
import GetProblemsRow from "../GetProblems/GetProblemsRow/GetProblemsRow"
const AllProblems = () => {
    const { data: serviceProblems, isloading } = useQuery(("serviceProblems"), () => fetch(`https://cutsew-server.onrender.com/getProblems`).then(res => res.json()))
    if (isloading) {
        <Loading></Loading>
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped table-dark table-bordered " >
                    <thead className="">
                        <tr >
                            <th scope="col">SL.NO</th>
                            <th scope="col">Leader Name</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Problems Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceProblems?.map((getOneProblem, index) => <GetProblemsRow
                                key={getOneProblem._id}
                                index={index + 1}
                                getOneProblem={getOneProblem}
                           
                            ></GetProblemsRow>)}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AllProblems