import React, { useEffect } from "react"
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import auth from "../../../../firebase.init";

const AddProblems = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [user] = useAuthState(auth)
  //*************get image url****************
  const handleImageChange = (event) => {
    const files = event.target.files;
    setImageFiles(files);
  };
  //**************others info for backend********************
  const { register, handleSubmit, reset } = useForm();
  // Submit your data into Redux store
  const onSubmit = async data => {
    const imageUrls = await Promise.all(Array.from(imageFiles).map(async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'mboyi3wd'); // Replace with your upload preset name
      formData.append('cloud_name', 'drmbkgue0'); // Replace with your Cloudinary cloud name
      const response = await fetch('https://api.cloudinary.com/v1_1/drmbkgue0/image/upload', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      return data.secure_url;
    }));
    const serviceProblems = {
      leaderName: data.leaderName,
      companyName: data.companyName,
      problemsName: data.problemsName,
      problemsStatus: data.problemsStatus,
      problemDescription: data.problemDescription,
      picture: imageUrls,
      email: user?.email,
      request:""
    }
    //send to your database
    fetch('https://cutsew-server.onrender.com/addProblems', {
      method: "POST",
      headers: {
        'content-type': "application/json",
      },
      body: JSON.stringify(serviceProblems)
    })
      .then(res => res.json())
      .then(output => {
        if (output.insertedId) {
          toast('Problems added successfully');
        } else {
          toast.error('Problemsadded unsuccessfully')
        }
      })
  };

  return (
    <div className="mb-5 mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* ----------Email---------- */}
        <div className="mb-2">
          <label> Email</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="productName" readOnly value={user?.email} />
          </div>
        </div>
        {/* ----------Your Name---------- */}
        <div className="mb-2">
          <label> Your Name</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="Your Name" {...register("leaderName")} required />
          </div>
        </div>
        {/* ----------Company Name---------- */}
        <div className="mb-2">
          <label> Company Name</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="Company Name" {...register("companyName")} required />
          </div>
        </div>
        {/* ----------Problems Name---------- */}
        <div className="mb-2">
          <label>Problems Title</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="Problems Name" {...register("problemsName")} required />
          </div>
        </div>
        {/* ----------Product Status---------- */}
        <div className="mb-3">
          <label>Problems Status</label>
          <div className='input-group'>
            <select {...register("problemsStatus")} className="form-control" required>
              <option value="pending">pending</option>
              <option value="solved">solved</option>
            </select>
          </div>
        </div>
        {/* ----------Product Description---------- */}
        <div className="mb-3">
          <label>Product Description</label>
          <div className='input-group'>
            <textarea type="text" rows="4" className="form-control" placeholder="problemDescription" {...register("problemDescription")} required />
          </div>
        </div>
        {/* ----------Product Image---------- */}
        <div className="mb-3">
          <label>Product Image</label>
          <input type="file" name="images" multiple onChange={handleImageChange} />
        </div>
        {/* -----------*********button********------------ */}
        <input className='signup-btn ms-0 mb-1 mt-2' type="submit" value="Add Problems" />
      </form>
    </div>
  )
}
export default AddProblems