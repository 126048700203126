import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { RecaptchaVerifier, signInWithPhoneNumber, } from "firebase/auth";
import { BsFillPersonFill, BsFillChatSquareDotsFill, BsFillEnvelopeOpenFill, BsEye, BsEyeSlash } from "react-icons/bs"
import '../SignUp/SignUp.css'
import { useCreateUserWithEmailAndPassword, useUpdateProfile } from 'react-firebase-hooks/auth';
import Loading from "../../../Loading/Loading";
import auth from "../../../firebase.init";
import { Helmet } from "react-helmet";

const PhoneSignIn = () => {
  const [showPassword, setShowPassword] = useState(true);
  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  //SignUp
  const [
    createUserWithEmailAndPassword,
    user,
    loading,
    error
  ] = useCreateUserWithEmailAndPassword(auth);
  const [updateProfile, updating, updateerror] = useUpdateProfile(auth);
  const navigate = useNavigate();
  const setUpRecaptha = (number) => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }
  const getOtp = async (e) => {
    e.preventDefault();
    setError1("");
    if (number === "" || number === undefined || number.length < 12)
      return setError1("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError1(err.message);
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError2("Please Match Your Password With Confirmpassword");
      return flag;
    }
    if (password.length < 6) {
      setError2("Password must be atleast 6 charecter")
    }
    createUserWithEmailAndPassword(email, password);
    await updateProfile({ displayName: name });
    const user = {
      name: name,
      email: email,
      role: '',
      Number: number,
      companyName: companyName,
      designation: designation,
      address: address,
    }
    // send to your database
    fetch('https://cutsew-server.onrender.com/userss', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(output => {
      })
    setError1("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      navigate("/");
    } catch (err) {
      setError1(err.message);
    }
  };
  if (loading || updating) {
    return <Loading></Loading>
  }
  if (user) {
    navigate('/')
  }
  return (
    <>
      <Helmet>
        <title>TKT Plotter</title>
      </Helmet>
      <div className='container' style={{ backgroundColor: "#E3E3E3" }}>
        <h2 className='form-title'>create An Account</h2>
        {error1 && <Alert variant="danger">{error1}</Alert>}
        <Form className='form-box mb-5' onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
          <Form.Group className="my-3" controlId="formBasicEmail">
            <label for="inputName" className="form-label mb-2">Your Phone Number</label>
            <PhoneInput
              maxLength={12}
              defaultCountry="BD"
              value={number}
              onChange={setNumber}
              placeholder="Enter Phone Number"
            />
            <div div id="recaptcha-container"></div>
          </Form.Group>
          <div className="button-right mb-5">
            <button className="signup-btn me-2" type="submit" >
              Verify
            </button>
            <Link to="/">
              <Button variant="secondary">Cancel</Button>
            </Link>
          </div>
        </Form>
        <div className='form-box ' style={{ display: flag ? "block" : "none" }}  >
          <Form onSubmit={verifyOtp} className='Signup-form shadow-sm px-3 py-2  bg-white rounded p-2'>
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputName" className="form-label m-0">Your OTP</label>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><BsFillPersonFill /></span>
                <Form.Control
                  className="form-control"
                  type="otp"
                  placeholder="Enter OTP"
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            {/* -----------====== Name ===----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputName" className="form-label m-0">Your Name</label>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><BsFillPersonFill /></span>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            {/* -----------====== Company-Name ===----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputName" className="form-label m-0">Company Name</label>
              <div className='input-group'>
                <span className="input-group-text"><BsFillChatSquareDotsFill /></span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            {/* -----------====== Designation ===----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputName" className="form-label m-0">Your Designation</label>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /></span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            {/* -----------====== Address ===----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputName" className="form-label m-0">Company Address</label>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /> </span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </Form.Group>
            {/* -----------=====Email====----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="exampleFormControlInput1" className="form-label m-0">Email address</label>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /></span>
                <Form.Control
                  className="form-control"
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            {/* -----------=====Password====----- */}
            <Form.Group className="mb-0" controlId="formBasicOtp">
              <label for="inputPassword" className="form-label">Password</label>
              <div className='input-group'>
                <span onClick={togglePassword} className='input-group-text  '>{showPassword ? <BsEye /> : <BsEyeSlash />}</span>
                <Form.Control
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error2 && <p className='text-danger p-0 m-0'>{error}</p>}
            </Form.Group>
            {/* -----------=====Confirm Password====----- */}
            <Form.Group className="mb-3" controlId="formBasicOtp">
              <label for="inputPassword" className="form-label">Password</label>
              <div className='input-group'>
                <span onClick={togglePassword} className='input-group-text  '>{showPassword ? <BsEye /> : <BsEyeSlash />}</span>
                <Form.Control
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              {error1 && <p className='text-danger p-0 m-0'>{error}</p>}
            </Form.Group>
            {error?.message && <p className='text-danger p-0 m-0'>{error.message}</p>}
            {updateerror?.message && <p className='text-danger p-0 m-0'>{updateerror.message}</p>}
            <div className="button-right mb-2">
              <button className="signup-btn me-2" type="submit" >
                Register
              </button>

              <Link to="/">
                <Button className="bg-secondary" variant="secondary">Cancel</Button>
              </Link>
            </div>
            <p>Already have an account? <Link to="/login" className="text-danger pe-auto text-decoration-none">Please Login</Link></p>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PhoneSignIn;