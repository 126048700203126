import React from 'react';
// import { useQuery } from 'react-query';
// import Loading from '../../Loading/Loading';
import ProductRow from './ProductRow/ProductRow';
import p1 from '../../Assets/home/p1.png';
import p2 from '../../Assets/home/p2.png';
import p3 from '../../Assets/home/p3.png';
import p4 from '../../Assets/home/p4.png';
import p5 from '../../Assets/home/p5.png';
import p6 from '../../Assets/home/p6.png';
import p7 from '../../Assets/home/p7.png';
import p8 from '../../Assets/home/p8.png';
import p9 from '../../Assets/home/p9.png';
import p10 from '../../Assets/home/p10.png';
import p11 from '../../Assets/home/p11.png';
import p12 from '../../Assets/home/p12.png';
import { BsArrowRight } from "react-icons/bs";
// import { FaAngleDoubleRight } from "react-icons/fa";
// import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import { Helmet } from 'react-helmet';
const Product = () => {
    //use react query
    // const { data: products, isLoading, refetch } = useQuery(('products'), () => fetch('https://cutsew-server.onrender.com/addsProduct').then(res => res.json()))
    // if (isLoading) {
    //     return <Loading></Loading>
    // }

    return (
        // <div className='container my-5'>
        //     <div className='row g-4'>
        //         {
        //             products?.map((products) => <ProductRow
        //                 key={products._id}
        //                 products={products}
        //                 refetch={refetch}
        //             >

        //             </ProductRow>)
        //         }
        //     </div>

        // </div>

        <div>
            <Helmet>
                <meta
                    name="description"
                    content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"
                />
                <meta
                    name="keywords"
                    content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"
                />
            </Helmet>       
            <div className="product-bg my-5">
                <div className="product-bg-white mb-5 pb-4">
                    <div className="container">
                        <div className="row gx-4 gy-5">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p1} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea 2D Pattern</h3>
                                        {/* <h4> <span> &euro;</span> 2000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p2} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Marker Making</h3>
                                        {/* <h4><span>&euro;</span> 2000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p3} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Auto marker</h3>
                                        {/* <h4><span>&euro;</span> 4200.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p4} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Camera Digitizer</h3>
                                        {/* <h4><span>&euro;</span> 2200.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p5} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>browzwear VStitcher </h3>
                                        {/* <h4><span>$</span> 8640.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p6} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>browzwear Style-zone </h3>
                                        {/* <h4><span>$</span> 1500.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p7} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Fabric analyzer </h3>
                                        {/* <h4><span>$</span> 9000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p8} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>NShot Camera Digitizer </h3>
                                        {/* <h4><span>$</span> 2700.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p9} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>TKT 180 2 head</h3>
                                        {/* <h4><span>$</span> 7500.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p10} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>TKT 220 2 head</h3>
                                        {/* <h4><span>$</span> 8000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p11} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>4 head Upgrade kit </h3>
                                        {/* <h4><span>$</span> 550.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p12} alt="TKT" loading="lazy"/>
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>HP45 Ink by TKT</h3>
                                        {/* <h4><span>$</span> 40.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;