import React from 'react';
import './Products.css';
import p1 from '../../../Assets/home/p1.png';
import p2 from '../../../Assets/home/p2.png';
import p3 from '../../../Assets/home/p3.png';
import p4 from '../../../Assets/home/p4.png';
import p5 from '../../../Assets/home/p5.png';
import p6 from '../../../Assets/home/p6.png';
import p7 from '../../../Assets/home/p7.png';
import p8 from '../../../Assets/home/p8.png';
import p9 from '../../../Assets/home/p9.png';
import p10 from '../../../Assets/home/p10.png';
import p11 from '../../../Assets/home/p11.png';
import p12 from '../../../Assets/home/p12.png';
import { FaAngleDoubleRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';


const Products = () => {
    return (
        <div>
            <div className="product">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <h2>our <strong className="black">products</strong></h2>
                                <div className='title-line pb-1 '>
                                    <div className='title-border p-title-border'>
                                        <div className='title-border-inner'></div>
                                        <div className='title-border-inner'></div>
                                        <div className='title-border-inner'></div>
                                        <div className='title-border-inner'></div>
                                    </div>
                                </div>
                                <span>We package the products with best services to make you a happy customer.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-bg ">
                <div className="product-bg-white mb-5 pb-4">
                    <div className="container">
                        <div className="row gx-4 gy-5">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p1} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea 2D Pattern</h3>
                                        {/* <h4> <span> &euro;</span> 2000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p2} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Marker Making</h3>
                                        {/* <h4><span>&euro;</span> 2000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p3} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Auto marker</h3>
                                        {/* <h4><span>&euro;</span> 4200.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p4} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Crea Camera Digitizer</h3>
                                        {/* <h4><span>&euro;</span> 2200.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p5} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>browzwear VStitcher </h3>
                                        {/* <h4><span>$</span> 8640.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p6} alt="TKT" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>browzwear Style-zone </h3>
                                        {/* <h4><span>$</span> 1500.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p7} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>Fabric analyzer </h3>
                                        {/* <h4><span>$</span> 9000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p8} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>NShot Camera Digitizer </h3>
                                        {/* <h4><span>$</span> 2700.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">

                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p9} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>TKT 180 2 head</h3>
                                        {/* <h4><span>$</span> 7500.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p10} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>TKT 220 2 head</h3>
                                        {/* <h4><span>$</span> 8000.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p11} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>4 head Upgrade kit </h3>
                                        {/* <h4><span>$</span> 550.00</h4> */}
                                        <button>More Details <BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <div className="product-box h-100">
                                    <Zoom>
                                        <img src={p12} alt="TKT" loading="lazy" />
                                    </Zoom>
                                    <div className='product-content'>
                                        <h3>HP45 Ink by TKT</h3>
                                        {/* <h4><span>$</span> 40.00</h4> */}
                                        <button>More Details<BsArrowRight/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Link className='load-btn' to="/product">Load More <FaAngleDoubleRight /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;