import React from 'react';
import './Footer.css';
import { FaLinkedinIn, FaTwitter, FaFacebookF, FaAngleDoubleRight, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { BsEnvelopeOpen, BsYoutube, BsInstagram } from "react-icons/bs";
import { Link } from 'react-router-dom';
import logo from '../../../Assets/Global/logo.png';
import ScrollToTop from 'react-scroll-to-top';
import { Helmet } from 'react-helmet';
import TKTPlotter from '../../../Assets/home/TKT Plotter.png'

const Footer = () => {
   return (
      <div>
         <Helmet>
            <meta id="title" property="title" content="TKT Plotter" />
            <meta id="og-title" property="og:title" content="TKT Plotter" />
            <meta property="og:image" content="../../../Assets/home/TKT Plotter.png" />
            <meta property="image" content='../../../Assets/home/TKT Plotter.png' />
            <meta name="keywords"
               content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"></meta>
            <meta name="description" content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D" />
            <img src={TKTPlotter} />
         </Helmet>
         <ScrollToTop smooth top="80" color="#FFC221" width="28px" height='28px' />
         <div className='footer-top'>
            <div className='container'>
               <div className='row align-items-start justify-content-center g-4 '>
                  {/* -------------col-1-------- */}
                  <div className='col-lg-4 col-md-6 col-12 order-1 order-lg-1'>
                     <div className='footer-top-content'>
                        <Link to="/" style={{ color: "black" }}><img className='footer-logo' src={logo} alt="TKT" /></Link>
                        <p>We are committed, to introduce new innovations of automation and promote them in garments industry with strong technical support.</p>
                        <div className='sociel d-flex justify-content-start'>
                           <Link to='https://www.facebook.com/CutSewTech' className=' '><FaFacebookF className='footer-icon fa' /></Link>
                           <Link to='/' className=' '><FaTwitter className='footer-icon tw' /></Link>
                           <Link to='/' className=' '><BsYoutube className='footer-icon yt' /></Link>
                           <Link to='/' className=' '><FaLinkedinIn className='footer-icon li' /></Link>
                           <Link to='/' className=' '><BsInstagram className='footer-icon in' /></Link>
                        </div>
                     </div>
                  </div>
                  {/* -------------col-2-------- */}
                  <div className='col-lg-3 col-md-6 col-12 ps-2 ps-lg-5 order-3 order-lg-2'>
                     <div className='footer-top-contents footer-top-content'>
                        <h5>CORE SERVICE</h5>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Industrial Racking</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Cutting Room Soluation</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Sewing Floor Soluation</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Finishing Equpment</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Technical support</Link>
                     </div>
                  </div>
                  {/* -------------col-3-------- */}
                  <div className='col-lg-2 col-md-6 col-12 order-4 order-lg-3'>
                     <div className='footer-top-contents footer-top-content'>
                        <h5>EXTRA SERVICE</h5>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Pattern Making</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Marker plotting</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Pattern Cutting</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Website Making</Link>
                        <Link to="/"><FaAngleDoubleRight className='FaAngle' /> Web Hosting</Link>
                     </div>
                  </div>
                  {/* -------------col-4-------- */}
                  <div className='col-lg-3 col-md-6 col-12 order-2 order-lg-4'>
                     <div className='footer-top-content'>
                        <h5>Contact</h5>
                        <div className='d-flex align-items-start'>
                           <FaPhoneAlt className='FaAngle mt-1 me-2' />
                           <div>
                              <p>+88 01400996655</p>
                              <p>+88 01400998844</p>
                           </div>
                        </div>
                        <div className='d-flex align-items-start my-3'>
                           <BsEnvelopeOpen className='FaAngle mt-1 me-2' />
                           <div>
                              <p>sales@cut-sew.com</p>
                              <p>service@cut-sew.com</p>
                           </div>
                        </div>
                        <div className='d-flex align-items-start my-3'>
                           <FaMapMarkerAlt className='FaAngle mt-1 me-2' />
                           <div>
                              <p>Sector-15, Block-D, Road-1, House-17 Momotaz Mohol 6th Floor, Uttara, Dhaka 1230, Bangladesh.</p>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* ----------footer-bottom-------- */}
         <div className='footer-bottom'>
            <div className='container'>
               <p>Copyright 2022, All Right Reserved By Cut-Sew Tech.</p>
            </div>
         </div>
      </div>
   );
};

export default Footer;