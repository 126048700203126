import React, { useEffect, useState } from 'react';
import {  useSendPasswordResetEmail, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import auth from '../../../firebase.init'
import Loading from '../../../Loading/Loading';
import './Login.css'
import {  BsFillEnvelopeOpenFill, BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Login = () => {
    const [showPassword, setShowPassword] = useState(true);
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }
    //login
    const [
        signInWithEmailAndPassword,
        user1,
        loading,
        error,
    ] = useSignInWithEmailAndPassword(auth);
    const [sendPasswordResetEmail, sending, error1] = useSendPasswordResetEmail(
        auth
    );
    //for from
    const navigate = useNavigate()
    const { register, formState: { errors }, handleSubmit } = useForm();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    // Submit your data into Redux store
    const onSubmit = data => {
        signInWithEmailAndPassword(data.email, data.password);
    };
    useEffect(() => {
        if (user1) {
            navigate(from, { replace: true });
        }
    }, [user1, from, navigate])
    if (loading) {
        return <Loading></Loading>
    }
    if (sending) {
        return <Loading></Loading>;
    }
    return (
        <div>
    
            <div className='container pb-5 pt-5' style={{ backgroundColor: "#E3E3E3" }}>
                <div className='form-box '>
                    <h2 className='form-title mb-4'>Login your account</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className='Signup-form shadow-sm p-3  bg-white rounded p-2' >
                        {/* ----------------Email--------------- */}
                        <div >
                            <label for="exampleFormControlInput1" className="form-label">Email address</label>
                            <div className='input-group'>
                                <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /></span>
                                <input  type="email" className="form-control form-controls" id="exampleFormControlInput1" placeholder="name@example.com" {...register("email", {
                                required: {
                                    value: true,
                                    message: 'Please Give Email'
                                },
                                pattern: {
                                    value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                    message: 'Privide  a Valid Email'
                                }
                            })} />
                            </div>
                            <label className="label">
                            {errors.email?.type === 'required' && <span className="label-text-alt text-danger">{errors.email.message}</span>}
                        </label>
                        </div>
                        {/* ----------------password--------------- */}
                        <div className="">
                            <label for="inputPassword" className="form-label">Password</label>
                            <div className='input-group'>
                                <span onClick={togglePassword} className='input-group-text  '>{showPassword ? <BsEye /> : <BsEyeSlash />}</span>
                                <input type={showPassword ? "text" : "password"} className="form-control form-controls" id="inputPassword" placeholder="Password" {...register("password", {
                                    required: {
                                        value: true,
                                        message: 'Please Give password'
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'Must be 6 Characters or longer'}
                                })} />
                            </div>
                            <label className="label">
                                {errors.password?.type === 'required' && <span className="label-text-alt text-danger">{errors.password.message}</span>}
                                {errors.password?.type === 'minLength' && <span className="label-text-alt text-danger">{errors.password.message}</span>}
                            </label>
                        </div>
                            {error?.message && <p className='text-danger'>{error.message}</p>}
                        {error1?.message && <p>{error1.message}</p>}
                          <input className='signup-btn ms-0 mb-4 mt-1' type="submit" value="Login" />
                        <p>New to here? <Link to="/signup" className="text-danger pe-auto text-decoration-none">Please SignUp</Link></p>
                        <p>Forgot Password? <Link to="" className="text-danger pe-auto text-decoration-none" onClick={async () => {
                            const emailFiled = document.getElementById('exampleFormControlInput1')
                            const email = emailFiled.value;
                            if (email) {
                                await sendPasswordResetEmail(email);
                                toast('Sent email');
                            } else {
                                toast('plese enter your email');}
                        }}>Reset Password</Link></p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;