import React from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useQuery } from "react-query"
import auth from "../../../../firebase.init"
import Loading from "../../../../Loading/Loading"
import GetProblemsRow from "./GetProblemsRow/GetProblemsRow"
const GetProblems = () => {
    const [user] = useAuthState(auth);
    const { data: getOneProblems, isLoading, refetch } = useQuery(('getOneProblems'), () => fetch(`https://cutsew-server.onrender.com/getOneProblems?email=${user?.email}`).then(res => res.json())
    )
    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped table-dark table-bordered " >
                    <thead className="">
                        <tr >
                            <th scope="col">SL.NO</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Problems Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { getOneProblems?.map((getOneProblem,index) => <GetProblemsRow
                                key={getOneProblem._id}
                                index={index+1}
                                getOneProblem={getOneProblem}
                                refetch={refetch}
                            ></GetProblemsRow>)}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default GetProblems