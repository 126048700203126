import React from 'react';
import { BsFillPersonFill, BsFillChatSquareDotsFill, BsFillEnvelopeOpenFill, BsFillTelephoneFill } from "react-icons/bs";
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import './Contact.css';



const Contact = () => {

    // -----for email----------
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_2as9fro", "template_ytuzvtd", e.target, "8VWdpqpe8wSHzfVzG").then(
            res => {
                toast("Message Sent Successfully");
            }
        )
    }
    return (
        <div className='contact-section'>
            <div className='common-title'>
                <div className='container'>
                    <h2>contact us</h2>
                </div>
            </div>
            <div className='container py-5'>
                {/* -----------contact-form---------- */}
                <div className='contact-form text-center'>
                    {/* -------Name------ */}
                    <form className='contact' onSubmit={sendEmail} >
                        <div className="input-group mb-3 ">
                            <span className="input-group-text" id="basic-addon1"><BsFillPersonFill /></span>
                            <input type="text" className="form-controls form-control" placeholder="Name" aria-label="Username" name="name" required />
                        </div>
                        {/* -----------Email---------  */}
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /></span>
                            <input type="email" className="form-controls form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon2" name="email" required />
                        </div>
                        {/* -----------Number---------  */}
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><BsFillTelephoneFill /></span>
                            <input type="umber" className="form-controls form-control" placeholder="Number" aria-label="Number" aria-describedby="basic-addon3" name="number" required />
                        </div>
                        {/* ------Message----------- */}
                        <div className="input-group">
                            <span className="input-group-text"><BsFillChatSquareDotsFill /></span>
                            <textarea  rows="5" className="form-control " aria-label="With textarea" placeholder='Message' name="message"></textarea>
                        </div>
                        <button className="form-btn mt-4" type="submit">send message</button>
                    </form>
                </div>
                </div>
  
            </div>
            );
};

            export default Contact;