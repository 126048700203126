import React from 'react';
const UserInfoRow = ({user,index}) => {
    const {name,email,companyName,designation,Number,address,}=user
    return (
        <tr>
          <td>{index}</td>
          <td>{name} <br/> {email}</td> 
          <td>{Number}</td>   
          <td>{companyName}</td>  
          <td>{designation}</td>    
          <td>{address}</td>  
        </tr>
    );
};

export default UserInfoRow;