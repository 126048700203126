import React from 'react';
import ChoseUs from '../Home/ChoseUs/ChoseUs';

import './About.css'

const About = () => {
    return (
        <div className='about-section '>
            <div className='common-title'>
                <div className='container'>
                    <h2>about us</h2>
                </div>
            </div>
            {/* ----------aboutus-section-------- */}
            <div className='aboutus-section'>
                <div className='container'>
                <h3>Story of Managing Director</h3>
               <p>He start his journey with Information technology (IT) in 1999. He was started his Job in 2003 with the garment industry as an IT expert. He gathered knowledge regarding CAD, CAS, CAM and Garments machinery partially in his service period. He Start his business in 2013 but he select his company name as CutSew Tech Ltd in 2019.</p>
               <h3 className='mt-5 pt-4'>Goal Of Cut-Sew Tech Ltd.</h3>
               <p> <strong> <b>Cut-Sew Tech Ltd.</b> </strong>is a growing company. We are Providing complete solution for garment industry in Bangladesh. We are committed, to introduce new innovations of automation and promote them in garments industry with strong technical support.</p>
                </div>
            </div>
            <ChoseUs></ChoseUs>

        </div>
    );
};

export default About;