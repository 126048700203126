import React from 'react';
import './ChoseUs.css';
import icon1 from './../../../Assets/home/1.png';
import icon2 from './../../../Assets/home/2.png';
import icon3 from './../../../Assets/home/3.png';
import icon4 from './../../../Assets/home/4.png';
import Zoom from 'react-reveal/Zoom';

const ChoseUs = () => {
    return (
        <>
            <div className='choseUs-section mt-4'>
                <div className='container'>
                    {/* ----------Title----------- */}
                    <div className="title">
                        <h2>Why <strong className="black">choose us</strong></h2>
                        <div className='title-line  '>
                            <div className='title-border'>
                                <div className='title-border-inner'></div>
                                <div className='title-border-inner'></div>
                                <div className='title-border-inner'></div>
                                <div className='title-border-inner'></div>
                            </div>
                        </div>
                        <span> Good Quality Product With Best Advantage</span>
                    </div>
                    {/* -------------content----------- */}
                    <div className='choseUs-content '>
                        <div className='row align-items-center justify-content-center gx-4 gy-5'>
                            {/* ----------content-1----------- */}
                            <div className='col-lg-6 col-md-6 col-12 pe-0 pe-lg-5'>
                                <Zoom>
                                    <div className='row align-items-center justify-content-center g-4'>
                                        <div className='col-lg-4 col-12'>
                                            <img className='d-block m-auto img-fluid' src={icon1} alt="TKT" />
                                        </div>
                                        <div className='col-lg-8 col-12'>
                                            <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                                                <div className='top-line'></div>
                                                <a href="/">Warehouse</a>
                                                <div className='top-line' ></div>
                                            </div>
                                            <p >We have expert Engineer, That's why we are capable to design your Warehouse as per space and loading capacity with proper utilization.</p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            {/* ----------content-2----------- */}
                            <div className='col-lg-6 col-md-6 col-12 '>
                                <Zoom>
                                    <div className='row align-items-center justify-content-center' >
                                        <div className='col-lg-4'>
                                            <img className='d-block m-auto img-fluid' src={icon2} alt="TKT" />
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                                                <div className='top-line'></div>
                                                <a href="/">Cutting Solution</a>
                                                <div className='top-line'></div>
                                            </div>
                                            <p >we are promoting The Fourth Industrial Revolution (Industry 4.0) for the cutting room like CAD, 3D dummy fitting, Auto fabric cutter and Spreader.</p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            {/* ----------content-3----------- */}
                            <div className='col-md-6 col-12 pe-0 pe-lg-5'>
                                <Zoom>
                                    <div className='row align-items-center justify-content-center' >
                                        <div className='col-lg-4'>
                                            <img className='d-block m-auto img-fluid' src={icon3} alt="TKT" />
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                                                <div className='top-line'></div>
                                                <a href="/">Sewing Solution</a>
                                                <div className='top-line'></div>
                                            </div>
                                            <p>The apparel industry is now moving fiercely towards Industry 4.0 Thats
                                                why we also try to introduce real time monitoring of sewing line.</p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            {/* ----------content-4----------- */}
                            <div className=' col-md-6 col-12 '>
                                <Zoom>
                                    <div className='row align-items-center justify-content-center' >
                                        <div className='col-lg-4'>
                                            <img className='d-block m-auto img-fluid' src={icon4} alt="TKT" />
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                                                <div className='top-line'></div>
                                                <a href="/">Finishing Product</a>
                                                <div className='top-line'></div>
                                            </div>
                                            <p className="justify-text">We are committed to provide the correct finishing equipment which will
                                                makes the operators work easier and reducing the need for additional specialist staff.</p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChoseUs;