import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../firebase.init';

const useServiceAdmin = () => {
    const [serviceAdmin,setServiceAdmin]=useState(false)
    const [user] = useAuthState(auth);
    useEffect(()=>{
        const email=user?.email
    
        fetch(`https://cutsew-server.onrender.com/serviceAdmin?email=${email}`,{
            method:"GET",
            headers:{
                'content-type':'application/json'
            }
        })
        .then(res=>res.json())
        .then(data=>{
            setServiceAdmin(data.serviceAdmin)
           
        })
   
    },[])

    return[serviceAdmin];
    
};

export default useServiceAdmin;