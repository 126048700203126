import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/autoplay";
import './Hero.css'
import slider1 from '../../../Assets/home/banner1.png';
import slider2 from '../../../Assets/home/TKT Plotter.png';
import slider3 from '../../../Assets/home/banner3.png';
import slider4 from '../../../Assets/home/banner4.png';
import slider5 from '../../../Assets/home/banner5.png';
import slider6 from '../../../Assets/home/banner6.png';
import slider7 from '../../../Assets/home/banner7.png';
import slider8 from '../../../Assets/home/banner8.png';
import slider9 from '../../../Assets/home/banner9.png';
import slider10 from '../../../Assets/home/banner10.png';
import slider11 from '../../../Assets/home/banner11.png';
import slider12 from '../../../Assets/home/banner12.png';
import slider13 from '../../../Assets/home/banner13.png';
import slider14 from '../../../Assets/home/banner14.png';
import slider15 from '../../../Assets/home/banner15.png';
import slider16 from '../../../Assets/home/banner16.png';
import slider17 from '../../../Assets/home/banner17.png';

const Hero = () => {
    return (
        <div className="hero-section">
            <div className="container">
                <Swiper
                    // install Swiper modules
                    modules={[Autoplay, Pagination,]}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                >
                    {/* -----------------slider-1------------ */}
                    <SwiperSlide className='slider-section'>
                        <div className="row gx-4  align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1 className="visually-hidden-focusable">TKT Plotter,TKT,3D,TKT</h1>
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p><strong>Browzwear</strong> produced <strong> Lotta, VStitcher </strong> and
                                        <strong>Stylezone</strong> <br />
                                        Which is the complete solution for virtual garment 3D fashion.
                                    </p>
                                    <a href="https://cut-sew.com/blog/blogdetails/3D-Browzwear" target="_blank">See Products
                                        Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider1} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-2-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p><strong>T-Dot</strong> Is A Sound Less Inkjet Plotter produced by <strong>TKT</strong><br />
                                        With Red Notch, Auto Paper cut and Optink special system. </p>
                                    <a href="https://cut-sew.com/blog/blogdetails/TKT%20Plotter" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider2} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-3-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p><strong>Jindex</strong> Is A renowned china Brand<br />
                                        This Inkjet Plotter have cloud printing technology and Four Head</p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider3} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-4-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Han Bond</strong> Havy duty Cutting Plotter capable to plot marker<br />
                                        And Cut the bigger pattern with <strong>accurate seam allowance.</strong></p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider4} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-5-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>SinaJet</strong> Flat Bed Pattern Cutter<br />
                                        Is A low noise Inkjet servo system pattern cutter. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider5} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-6-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>G-Tech</strong> Flat Bed Pattern Cutter<br />
                                        Is a continue cut system pattern cutter with conveyor belt. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider6} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* -------------slider-7-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Han Bond</strong> Multilayer Template Cutting Machine<br />
                                        It can cut once 8 mm thickness within the 1-5 layers template.</p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider7} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-8-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Tesan</strong> Is very Good quality Spreader Machine<br />
                                        Manufacturing By turkey which running cost is 30% less than the other.</p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider8} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-9-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>UniCut</strong> Fabric Cutter Machine<br />
                                        is capable to cut up to 8 cm compress fabric. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider9} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-10-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center justify-content-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p>Rambo Is a highly efficient Auto Pocket Setter <br/> Machine with the Brother or Juki Head. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider10} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-17-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Xinding Snap Button Machine </strong> appearance patent<br />
                                        High safety,hight speed,high specision </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider17} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-16-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center ">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Seam Sealing Machine</strong> is mainly used for seam sealing<br />
                                        This process is to prevent water, bacteria, viruses, air, etc </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider16} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-11-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center justify-content-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> Want to increase<strong> 20% profit</strong> margin?<br />
                                        So ETS is the best solution for your industries. </p>
                                    <a href="https://cut-sew.com/blog/blogdetails/ETS" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider11} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-12-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center justify-content-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Eastman</strong> band knife cutter is very famous.<br />
                                        This machines can cut small-scale as well as complex shape components. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider12} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-13-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center justify-content-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Oshima</strong> produced finishing machinery for apparel industries<br />
                                        Oshima have double and single metal detection system. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider13} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-14-------------- */}
                    <SwiperSlide>
                        <div className="row gx-4 align-items-center justify-content-center">
                            <div className="col-md-6 col-12 order-2 order-md-1">
                                <div className="slider-content">
                                    <h1>Our <br /> <strong className="black_bold">Latest </strong><br />
                                        <strong className="yellow_bold">Product </strong> </h1>
                                    <p> <strong>Jindex</strong> Single Ply sample garments cutting Machine.<br />
                                        It helps the sample maker to cut Single Ply fabric by CAD Pattern. </p>
                                    <a href="/" target="_blank">See Products Details</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-1 order-md-2">
                                <img className="slider-img" src={slider14} alt="TKT" loading="lazy" />
                            </div>
                        </div>
                    </SwiperSlide>
           
                </Swiper>
            </div>
        </div>
    );
};

export default Hero;