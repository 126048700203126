import React, {    } from 'react';
import './SignUp.css'
// import { useCreateUserWithEmailAndPassword, useUpdateProfile } from 'react-firebase-hooks/auth';
// import { useForm } from 'react-hook-form';
// import { Link, useNavigate } from 'react-router-dom';
// import auth from '../../../firebase.init';
// import Loading from '../../../Loading/Loading';
// import { BsFillPersonFill, BsFillChatSquareDotsFill, BsFillEnvelopeOpenFill, BsEye, BsEyeSlash } from "react-icons/bs";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
// import { Controller } from "react-hook-form";
// import 'react-phone-number-input/style.css'
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SignUp = () => {

    // const validationSchema = Yup.object().shape({
    //     password: Yup.string()
    //         .required('Password is required')
    //         .min(6, 'Password must be at least 6 characters'),
    //     confirmPassword: Yup.string()
    //         .required('Confirm Password is required')
    //         .oneOf([Yup.ref('password')], 'Passwords must match'),

    //     phoneNumber: Yup.string()
    //         .required('Phone number.required')
    //         .min(14, 'Password must be at least 11 characters'),
    // });

    // const [showPassword, setShowPassword] = useState(true);
    // const togglePassword = () => {
    //     setShowPassword(!showPassword)
    // }
    // //SignUp
    // const [
    //     createUserWithEmailAndPassword,
    //     user,
    //     loading,
    //     error,
    // ] = useCreateUserWithEmailAndPassword(auth);
    // //update user profile
    // const [updateProfile, updating, updateerror] = useUpdateProfile(auth, { sendEmailVerification: true });
    // const navigate = useNavigate();

    // //for from
    // const formOptions = { resolver: yupResolver(validationSchema) };

    // const {
    //     handleSubmit,
    //     formState: { errors },
    //     control,
    //     register,
    // } = useForm(formOptions);

        
    // // Submit your data into Redux store
    // const onSubmit = async data => {
    //     await createUserWithEmailAndPassword(data.email, data.password);
    //     await updateProfile({ displayName: data.name });
    //     const user = {
    //         name: data.name,
    //         email: data.email,
    //         role: '',
    //         companyName: data.companyName,
    //         Dsignation: data.Dsignation,
    //         Number: data.phoneNumber,
    //         Address: data.Address,

    //     }
    //     // send to your database
    //     fetch('https://cutsew-server.onrender.com/user', {
    //         method: "POST",
    //         headers: {
    //             'content-type': "application/json",
    //         },
    //         body: JSON.stringify(user)
    //     })
    //         .then(res => res.json())
    //         .then(output => {

    //         })
    //     return false;
    // };
    // if (loading || updating) {
    //     return <Loading></Loading>
    // }
    // if (user) {
    //     navigate('/')
    // }
    return (
        <></>
        // <div className=''>
        //     <div className='container' style={{ backgroundColor: "#E3E3E3" }}>
        //         <div className='form-box '>
        //             <form onSubmit={handleSubmit(onSubmit)} className='Signup-form shadow-sm px-3 py-2  bg-white rounded p-2' >
        //                 <h2 className='form-title'>create An Account</h2>
        //                 {/* ----------------Name--------------- */}
        //                 <div className="m-0 p-0">
        //                     <label for="inputName" className="form-label">Your Name</label>
        //                     <div className='input-group'>
        //                         <span className="input-group-text" id="basic-addon1"><BsFillPersonFill /></span>
        //                         <input type="text" className="form-control" id="inputName" placeholder="name" required {...register("name", {
        //                             required: {
        //                                 value: true,
        //                                 message: 'Please Give Name'
        //                             },
        //                         })} />
        //                     </div>

        //                 </div>
        //                 {/* ----------------Company Name--------------- */}
        //                 <div className="m-0 p-0">
        //                     <label for="inputcompanyName" className="form-label">Company Name</label>
        //                     <div className='input-group'>
        //                         <span className="input-group-text"><BsFillChatSquareDotsFill /></span>
        //                         <input type="text" className="form-control" required id="inputcompanyName" placeholder="companyName"{...register("companyName", {
        //                             required: {
        //                                 value: true,
        //                                 message: 'Please Give Company Name'
        //                             },
        //                         })} />
        //                     </div>
        //                 </div>
        //                 {/* ----------------Dsignation--------------- */}
        //                 <div className="">
        //                     <label for="inputDesignation" className="form-label">Dsignation</label>
        //                     <div className='input-group'>
        //                         <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill />                          </span>
        //                         <input type="text" className="form-control" id="inputDesignation" placeholder="Dsignation" required {...register("Dsignation", {
        //                             required: {
        //                                 value: true,
        //                                 message: 'Please Give Your Dsignation'
        //                             },
        //                         })} />
        //                     </div>
        //                 </div>
        //                 {/* ----------------Phone Number--------------- */}
        //                 <div className="">
        //                     <div>
        //                         <label htmlFor="phone-input">Phone Number</label>
        //                         <Controller
        //                             name="phoneNumber"
        //                             control={control}
        //                             rules={{
        //                                 validate: (value) => isValidPhoneNumber(value)
        //                             }}
        //                             render={({ field: { onChange, value } }) => (
        //                                 <PhoneInput
        //                                     value={value}
        //                                     onChange={onChange}
        //                                     defaultCountry="BD"
        //                                     id="phone-input"
        //                                     placeholder="Enter Number"
        //                                     required
        //                                     maxLength={12}
        //                                 />
        //                             )}
        //                         />
        //                        
        //                         <div id="recaptcha-container"></div>
        //                     </div>
        //                 </div>
        //                 {/* ----------------Address--------------- */}
        //                 <div >
        //                     <label for="inputAddress" className="form-label">Company Address</label>
        //                     <div className='input-group'>
        //                         <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill />       </span>

        //                         <input type="text" className="form-control" id="inputAddress" placeholder="Address"{...register("Address", {

        //                         })} />
        //                     </div>
        //                 </div>
        //                 {/* ----------------Email--------------- */}
        //                 <div >
        //                     <label for="exampleFormControlInput1" className="form-label">Email address</label>
        //                     <div className='input-group'>
        //                         <span className="input-group-text" id="basic-addon1"><BsFillEnvelopeOpenFill /></span>
        //                         <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required {...register("email", {
        //                             required: {
        //                                 value: true,
        //                                 message: 'Please Give Email'
        //                             },
        //                             pattern: {
        //                                 value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
        //                                 message: 'Privide  a Valid Email'
        //                             }
        //                         })} />
        //                     </div>
        //                 </div>
        //                 {/* ----------------password--------------- */}
        //                 <div className="">
        //                     <label for="inputPassword" className="form-label">Password</label>
        //                     <div className='input-group'>
        //                         <span onClick={togglePassword} className='input-group-text  '>{showPassword ? <BsEye /> : <BsEyeSlash />}</span>
        //                         <input type={showPassword ? "text" : "password"} id="inputPassword" placeholder="Password" required {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
        //                     </div>
        //                 </div>

        //                 <div className="text-danger">{errors.password?.message}</div>

        //                 {/* ----------------Confirmpassword--------------- */}
        //                 <div className="">
        //                     <label for="inputConfirmPassword" className="form-label">Confirm Password</label>
        //                     <div className='input-group'>
        //                         <span onClick={togglePassword} className='input-group-text  '>{showPassword ? <BsEye /> : <BsEyeSlash />}</span>
        //                         <input type={showPassword ? "text" : "password"} id="inputConfirmPassword" placeholder="confirmPassword" required {...register("confirmPassword")}
        //                             className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
        //                     </div>
        //                     <div className=" text-danger pt-2">{errors.confirmPassword?.message}</div>
        //                 </div>

        //                 {
        //                     error?.message && <p className='text-danger p-0 m-0'>{error.message}</p>
        //                 }
        //                 {
        //                     updateerror?.message && <p className='text-red-500 p-0 m-0'>{updateerror.message}</p>
        //                 }

        //                 <input className='signup-btn ms-0 mb-1 mt-2' type="submit" value="SignUp" />
        //                 <p>Already have an account? <Link to="/login" className="text-danger pe-auto text-decoration-none">Please Login</Link></p>
        //             </form>

        //         </div>
        //     </div>
        // </div>
    );
};

export default SignUp;