import React from 'react';
import './Map.css'

const Map = () => {
    return (
    <div className='map-border'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3648.4060085056876!2d90.38003071486304!3d23.875217489913723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c549daa30523%3A0xd4c5d2cd79f4ddf!2sCutSew%20Tech%20Ltd!5e0!3m2!1sen!2sbd!4v1672125630058!5m2!1sen!2sbd" width="100%" height="350"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default Map;
