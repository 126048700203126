import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../../firebase.init';
import { ImBin } from "react-icons/im";
import { RiAdminLine } from "react-icons/ri";
import { MdAdminPanelSettings} from "react-icons/md";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const UsersRow=({user,refetch,index}) => {
const {name,email,role}=user
const handleDelete= (Id)=>{
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove!',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            // fetch(`https://cutsew-server.onrender.com/removeuser/${Id}`,{
            //     method:"Delete",
            //     headers:{
             
            //     }
            // })
            //   .then(res=>res.json())
            //   .then(data=>{
          
            // })
            fetch(`https://cutsew-server.onrender.com/removeAdmin?email=${user1?.email}`, {
                method: 'PUT',
                headers: {
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Headers" : "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                },
                body:JSON.stringify({email})
            }).then(res => {
                    if(res.status === 403){
                        toast.error('Failed to Remove an admin');
                    }
                    return res.json()})
                .then(data => {
              if(data.modifiedCount>0){
                refetch()
                toast.success('Successfully Remove an admin');
              }
                })
        } else if (!result.isConfirmed) {
            Swal.fire(
                refetch(),
                'Remove!',
                 )
        } else {
            Swal.fire(
                refetch(),
                'Remove!',
               'Your file has been deleted.',
                'Unsuccess'
                 )
        }
      })
}

const [user1] = useAuthState(auth);
// ---------MainAdmin----------
const makeAdmin = () => {
    fetch(`https://cutsew-server.onrender.com/verifyUsers?email=${user1?.email}`, {
        method: 'PUT',
        headers: {
            "Content-Type" : "application/json",
        },
        body:JSON.stringify({email})
    })
        .then(res => {
            if(res.status === 403){
                toast.error('Failed to Make an admin');
            }
            return res.json()})
        .then(data => {
      if(data.modifiedCount>0){
        refetch()
        toast.success('Successfully Make an admin');
      }
        })
    }
// ---------ServiceAdmin----------
const makeSerAdmin = () => {
    fetch(`https://cutsew-server.onrender.com/verifyService?email=${user1?.email}`, {
        method: 'PUT',
        headers: {
            "Content-Type" : "application/json",
        },
        body:JSON.stringify({email})
    }).then(res => {
            if(res.status === 403){
                toast.error('Failed to Make an admin');
            }
            return res.json()})
        .then(data => {
      if(data.modifiedCount>0){
        refetch()
        toast.success('Successfully Make an admin');
      }
        })
    }
    return (
     <tr>
        <td> {index}</td>
        <td> {name}</td>
        <td>{email} </td>
        <td>
        <td>
            {role !== 'serviceAdmin' && <button onClick={makeSerAdmin} className="btn btn-success " title='Main Admin'><RiAdminLine /></button>}
        </td>
        </td>
        <td>
            {role !== 'admin' && <button onClick={makeAdmin} className="btn btn-primary " title='Main Admin'><MdAdminPanelSettings/></button>}
        </td>
        <td><button onClick={ handleDelete} className="btn btn-danger"><ImBin /></button></td>
     </tr>
    );
};

export default UsersRow;