import { image } from "@cloudinary/url-gen/qualifiers/source"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import Loading from "../../../../Loading/Loading"
import ModalImage from "react-modal-image";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const ServiceDetails = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { id } = useParams()
    const url = `https://cutsew-server.onrender.com/updateStatus/${id}`
    const { data: details, isLoading } = useQuery(['details', id], () => fetch(url, {
    })
        .then(res => res.json())
    )
    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <>
            <div className="container my-4 text-center">
                {details?.picture ?
                    <>
                        {details?.picture?.map((picture) => (
                            <div style={{ width: "250px", display: "inline-block", margin: "0 auto" }}>  <ModalImage
                                small={picture}
                                large={picture}
                            /></div>
                        ))}
                    </>
                    :
                    ""}
                <h4 className=" my-0">{details?.problemsName} Problems</h4>
                <p>{details?.problemDescription}</p>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <div style={{ width: "100px", height: "1px", backgroundColor: "#FFC221" }}></div>
                    <div className="mx-2 fw-bolder ">Author</div>
                    <div style={{ width: "100px", height: "1px", backgroundColor: "#FFC221" }}></div>
                </div>
                <p className="my-0 text-black fst-italic"><span className="text-danger fw-bolder">LeaderName: </span>{details?.leaderName}</p>
                <p className="my-0 text-black fst-italic"><span className="text-danger fw-bolder">Email:</span> {details?.email}</p>
                <p className="my-0 text-black fst-italic"><span className="text-danger fw-bolder">ProblemsStatus: </span>{details?.problemsStatus}</p>
            </div>

        </>
    )
}
export default ServiceDetails