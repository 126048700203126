import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';


const Facebook = () => {
    return (
        <div>
           <MessengerCustomerChat
                pageId="109619895363149"
                appId="906247030576651"
            /> 
         
        </div>
    );
};

export default Facebook;



