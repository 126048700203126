import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, NavLink, Outlet } from 'react-router-dom';
import auth from '../../../firebase.init';
import useAdmin from '../../../Hooks/useAdmin/useAdmin';
import useServiceAdmin from '../../../Hooks/useServiceAdmin';
import Accordion from 'react-bootstrap/Accordion';
import { FaHandPointRight } from "react-icons/fa";
import './Dashboard.css'
import { BsFilterLeft } from 'react-icons/bs';



const Dashboard = () => {
    const [show, setShow] = useState(false);
    const [user] = useAuthState(auth)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [admin] = useAdmin(user)
    const [serviceAdmin] = useServiceAdmin(user)
    return (
        <div className='container-fluid '>

            {/* --------------------for mobile tablet-------------   */}
            <div className='d-block d-md-none mt-2 '>
                <div className='row align-items-start'>
                    <div className='col-12 '>
                        <a onClick={handleShow}>
                            <BsFilterLeft className='d-menu' />
                        </a>
                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title></Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {/* <!-- Sidebar content here --> */}
                                <li className='text-center'>  <Link to="/dashboard">Dashboard</Link></li>
                                {admin &&
                                    <>
                                        <Accordion className='my-3'>
                                            {/* -------------------------------------- */}
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Users</Accordion.Header>
                                                <Accordion.Body>
                                                    <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/us9er9s9" >Make Admin</NavLink ></li>
                                                    <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/userinfoC57c" >UsersInfo</NavLink></li>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* -------------***************-------------- */}
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Product</Accordion.Header>
                                                <Accordion.Body>
                                                    <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/productscut75" >AddProducts</NavLink></li>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* --------*****************--------------- */}
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Service</Accordion.Header>
                                                <Accordion.Body>
                                                    <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/problAllems=cut=all=735" >ServiceProblems</NavLink></li>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    </>}
                                {serviceAdmin &&
                                    <>
                                        <li> <FaHandPointRight className='text-warning' />  <NavLink to="/dashboard/servic5=cut=add=problems" >AddProblems</NavLink></li>
                                        <li><FaHandPointRight className='text-warning' />  <NavLink to="/dashboard/problems=cut=all=825" >GetProblems</NavLink></li>
                                    </>}
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                    <div className='col-12 vh-100 overflow-auto mt-3'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
            {/* --------------------for desktop-------------   */}
            <div className='d-none d-md-block'>
                <div className='row'>
                    <div className='col-2 dashboard-main text-center p-0'>
                        <div className='vh-100 overflow-auto'>
                            {/* <!-- Sidebar content here --> */}
                            <li>  <Link to="/dashboard">Dashboard</Link></li>
                            {admin &&
                                <>
                                    <Accordion className='my-3'>
                                        {/* -------------------------------------- */}
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Users</Accordion.Header>
                                            <Accordion.Body>
                                                <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/us9er9s9" >Make Admin</NavLink ></li>
                                                <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/userinfoC57c" >UsersInfo</NavLink></li>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* -------------***************-------------- */}
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Product</Accordion.Header>
                                            <Accordion.Body>
                                                <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/productscut75" >AddProducts</NavLink></li>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* --------*****************--------------- */}
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Service</Accordion.Header>
                                            <Accordion.Body>
                                                <li><FaHandPointRight className='text-warning' /> <NavLink to="/dashboard/problAllems=cut=all=735" >ServiceProblems</NavLink></li>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>


                                </>}
                            {serviceAdmin &&
                                <>
                                    <li> <FaHandPointRight className='text-warning' />  <NavLink to="/dashboard/servic5=cut=add=problems" >AddProblems</NavLink></li>
                                    <li><FaHandPointRight className='text-warning' />  <NavLink to="/dashboard/problems=cut=all=825" >GetProblems</NavLink></li>
                                </>}
                        </div>
                    </div>
                    <div className='col-10 vh-100 overflow-auto'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;