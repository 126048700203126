import React from 'react';
import { FaLinkedinIn, FaTwitter, FaFacebookF, FaPinterest, FaHandPointRight, } from 'react-icons/fa';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import logo from '../../../Assets/Global/logo.png';
import { BsYoutube, BsInstagram } from "react-icons/bs";
import './Header.css'
import { Typewriter } from 'react-simple-typewriter';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import TKTPlotter from '../../../Assets/home/TKT Plotter.png'
const Header = () => {
    const [user, loading,] = useAuthState(auth);
 
    const signout = () => {
        signOut(auth);
    };
    if (Link === "/") {
        document.getElementById("demo").classList.add("color")
    }
    return (
        <>

            <Helmet>
       
            <meta name="title" content="TKT Plotter"/>
                <meta id="og-title" property="og:title" content="TKT Plotter" />
                <meta property="og:image" content="../../../Assets/home/TKT Plotter.png" />
                <meta property="image" content='../../../Assets/home/TKT Plotter.png' />
                <img src={TKTPlotter} />
                <meta name="keywords"
                    content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"></meta>
                <meta name="description" content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"/>
            </Helmet>
           
            {/* -------------top-header start------- */}
            <div className='top-header d-none d-md-block'>
                <div className='container'>
                    <div className='row g-0 g-lg-4 align-items-center '>
                        <div className='col-md-3 col-12'>
                            <div className='header-icon d-flex justify-content-center'>
                                <Link to='https://www.facebook.com/CutSewTech' ><FaFacebookF className='top-icon fb' /></Link>
                                <Link to='/' className=' ms-3'><FaTwitter className='top-icon tr' /></Link>
                                <Link to='/' className=' ms-3'><BsYoutube className='top-icon yb' /></Link>
                                <Link to='/' className=' ms-3'><FaLinkedinIn className='top-icon lk' /></Link>
                                <Link to='/' className=' ms-3'><BsInstagram className='top-icon im' /></Link>
                            </div>
                        </div>
                        <div className='col-md-5 col-12 text-center justify-content-center'>
                            <p>Save your “Time = Money”, with an expert services team</p>
                        </div>
                        <div className='col-md-4 col-12  text-end animated-header d-flex align-items-center'
                        >
                            <h6 className='m-0 p-0 '> Call Us </h6>
                            <FaHandPointRight className='text-white mx-1' />
                            <p >
                                {' '}
                                <span style={{ color: 'black', fontWeight: 'bold', fontFamily: "tahoma" }}>
                                    {/* Style will be inherited from the parent element */}
                                    <Typewriter
                                        words={['Sales: +8801400992233', 'Service: +8801400998844', 'Service: +8801400996655']}
                                        loop="infinity"
                                        cursor
                                        cursorStyle=''
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={2500}
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* -------------top-header end------- */}
            {/* -------------main-header start------- */}
            <Navbar collapseOnSelect expand="lg" sticky="top" className='bg' >
                <Container >
                    <Navbar.Brand as={Link} to="/">
                        <img className='logo' src={logo} alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            <NavLink id='demo' as={Link} to="/" >Home</NavLink>
                            <NavLink as={Link} to="/about" className="my-2 my-lg-0">about</NavLink>
                            <NavLink as={Link} to="/product">product</NavLink>
                            <NavLink as={Link} to="/blog" className="my-2 my-lg-0">blog</NavLink>
                            <NavLink as={Link} to="/contact">contact</NavLink>
                            {user &&
                                <NavLink as={Link} to="/dashboard" className="my-2 my-lg-0">Dashboard</NavLink>}
                        </Nav>
                        <Nav>
                            {user ?
                                <Link onClick={signout} to="" className='header-btn  mb-3 mb-lg-0 me-4'>Log Out</Link>
                                :
                                <Link to="/login" className='header-btn  mb-3 mb-lg-0 me-4'>Login</Link>}
                            <Link to="/phonesignIn" className=' register-btn mb-3 mb-lg-0'>Register</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* -------------main-header end------- */}
        </>
    );
};

export default Header;