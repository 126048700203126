import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import './BlogDetails.css'

const BlogDetails = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState([])
    useEffect(() => {
        fetch('/blog.json')
            .then(res => res.json())
            .then(data => {
                const findBlogDetails = data.find(data => data.id === id)
                console.log(findBlogDetails)
                if (findBlogDetails) {
                    setBlog(findBlogDetails);
                }
            })
    }, [id])
    return (
        <>
            <div className="container">
                <img className="img-fluid d-block m-auto" src={blog.image} alt="blog" loading="lazy" />
                <div className="blog-details-content">
                    {/* **************All common Blog************** */}
                    <h2>{blog.name}</h2>
                    <h1>{blog.title}</h1>
                    <p className="mt-2">{blog.description}</p>

                    {
                        blog.tktinklink &&
                        <>
                          <h3>Advantace Of TKT INK</h3>
                          <p>1.   TKT HP-45 are capable to plot with 1 pixel but we suggest 2 pixel for good view (almost 35% Saver then HP-45)   </p>
                          <p>2. Ultra Black: TKT HP-45 ink is described as "ultra black." This implies that the ink produces a deep and intense black color when plotted, which can be beneficial for various applications. The high contrast of the ultra-black ink can make it easier for manual cutter operators to understand the notches and other parameters on plotted materials. </p>
                          <p>3. Also 100% reliable (Original)</p>

                          <p> To know more about TKT vist TKT Brain Power main website <a href={blog.tktinklink} target="_blank">TKT Brain Power</a> </p>
                        </>
                    }
                    {
                        blog.tktplotterlink &&
                        <>
                            <h3>Advantace Of TKT Plotter</h3>
                           <p> 1. Low noise printing with precise Alignment.</p>
                            <p>2. High speed 200M2/hour</p>
                           <p> 3. Servo motor Control.</p>
                            <p>4. Material Scroll or single paper non-woven paper (20gsm -180gsm)</p>
                            <p>5. Easy paper loading at ground level; waist-level paper take-up</p>
                            <p>6. Easily configure for “drop-on-floor” or “take-up” plotting mode</p>
                           <p> 7. Low power consumption: 70W/80W</p>
                           <p> 8. Multi communication system -USB 2.0 / Lan interface</p>
                           <p> 9. Easy to control from different Computer over interface.</p>
                            <p>10. Extremely easy to change and fix new paper roll. By double bar</p>
                            <p>11. Reduced ink consumption compared with conventional system.</p>
                            <p>12. Reversible rotation bar.</p>
                            <p>13. Dynamic Alignment system that help you to keep alignment straight.</p>
                            <p>14. Aluminium cover & shaft.</p>
                            <p>15. Simple assemble, less spare parts & lase trouble.</p>
                            <p>16. Multi optional advance interface.</p>
                            <p>17. Marker unrolling is too easy</p>
                            <p>18. Inverter System</p>
                            <p>19. Ability to resume plot from the last printing position after load shedding.</p>
                           <p> 20.Auto Cut paper without wasting time and paper. Continue print then cut.</p>

                            <p> To know more about TKT vist TKT Brain Power main website <a href={blog.tktplotterlink} target="_blank">TKT Brain Power</a> </p>
                        </>
                    }

                    <p>{blog.description2}</p>
                    {/* -------------*********************Blog-1 crea cad***********************------------- */}
                    {blog?.firstDes &&
                        <>
                            <h3>{blog?.first}</h3>
                            <p>{blog?.firstDes}</p>
                        </>}
                    {blog?.benifit &&
                        blog?.benifit?.map((d) => <li>{d.benifit}</li>)}
                    {blog?.firstDes &&
                        <p className="my-3">{blog?.bellow}</p>}
                    {/* -------******* benifit2 *******----------- */}
                    {blog?.benifit2 &&
                        <>
                            <h3>{blog?.second}</h3>
                            <p>{blog.secondDes}</p>
                        </>}
                    {blog?.benifit2 &&
                        blog?.benifit2?.map((d) => <li>{d.benifit2}</li>)}
                    {/* -------*******benifit3*******----------- */}
                    {blog?.benifit3 &&
                        <>
                            <h3>{blog?.third}</h3>
                            <p>{blog.thirdDes}</p>
                        </>}
                    {blog?.benifit3 &&
                        blog?.benifit3?.map((d) => <li>{d.benifit3}</li>)}
                    {/* -------*******benifit4*******----------- */}
                    {blog?.benifit4 &&
                        <>
                            <h3>{blog?.four}</h3>
                            <p>{blog.fourDes}</p>
                            <p>{blog.fourDes2}</p>
                        </>}
                    {blog?.benifit4 &&
                        blog?.benifit4?.map((d) => <li>{d.benifit4}</li>)}
                    {/* -------*******benifit5*******----------- */}
                    {blog?.benifit5 &&
                        <>
                            <h3>{blog?.five}</h3>
                            <p>{blog.fourDes}</p>
                        </>}
                    {blog?.benifit5 &&
                        blog?.benifit5?.map((d) => <li>{d.benifit5}</li>)}
                    {blog?.benifit5 &&
                        <p className="mt-3">{blog?.bellow}</p>}
                    {/* -------******benifit6 ********----------- */}
                    {blog?.benifit6 &&
                        <>
                            <h3>{blog?.six}</h3>
                            <p>{blog.fourDes}</p>
                        </>}
                    {blog?.benifit6 &&
                        blog?.benifit6?.map((d) => <li>{d.benifit6}</li>)}
                    {/* -------*******benifit7*******----------- */}
                    {blog?.benifit7 &&
                        <h3>{blog?.seven}</h3>}
                    {blog?.benifit7 &&
                        blog?.benifit7?.map((d) => <li>{d.benifit7}</li>)}
                    {/* -------*******benifit8*******----------- */}
                    {blog?.benifit8 &&
                        <h3>{blog?.eight}</h3>}
                    {blog?.benifit8 &&
                        blog?.benifit8?.map((d) => <li>{d.benifit8}</li>)}
                    {/* -------*******benifit9*******----------- */}
                    {blog?.benifit9 &&
                        <h3>{blog?.nine}</h3>}
                    {blog?.benifit9 &&
                        blog?.benifit9?.map((d) => <li>{d.benifit9}</li>)}
                    {/* -------*******benifit10*******----------- */}
                    {blog?.benifit10 &&
                        <h3>{blog?.ten}</h3>}
                    {blog?.benifit10 &&
                        blog?.benifit10?.map((d) => <li>{d.benifit10}</li>)}
                    {/* -------*******benifit11*******----------- */}
                    {blog?.benifit11 &&
                        <h3>{blog?.eleven}</h3>}
                    {blog?.benifit11 &&
                        blog?.benifit11?.map((d) => <li>{d.benifit11}</li>)}
                    {/* -------*******benifit12*******----------- */}
                    {blog?.benifit12 &&
                        <h3>{blog?.tweelve}</h3>}
                    {blog?.benifit12 &&
                        blog?.benifit12?.map((d) => <li>{d.benifit12}</li>)}
                    {/* -------*******benifit13*******----------- */}
                    {blog?.benifit13 &&
                        <h3>{blog?.thirteen}</h3>}
                    {blog?.benifit13 &&
                        blog?.benifit13?.map((d) => <li>{d.benifit13}</li>)}
                    {/* -------*******benifit14*******----------- */}
                    {blog?.benifit14 &&
                        <h3>{blog?.fourteen}</h3>}
                    {blog?.benifit14 &&
                        blog?.benifit14?.map((d) => <li>{d.benifit14}</li>)}
                    {/* -------------------**********3D Blog*************--------------- */}
                    <h3>{blog?.threeDTitle}</h3>
                    {blog?.bdesone &&
                        <>
                            <p>{blog?.threeDdesfour}</p>
                            <h3>3D Fashion Design:</h3>
                            <p>{blog?.bdesone}</p>
                            <h3>Browzwear:</h3>
                            <p>{blog?.bdestwo}</p>
                            <h3>VStitcher:</h3>
                            <p>{blog?.caddesone}</p>
                            <h3>Lotta:</h3>
                            <p>{blog?.cthreeDdes}</p>
                            <h3>Style-zone: </h3>
                            <p>{blog?.samdes}</p>
                            <h3 className="fw-bolder">Fabric Analyzer: </h3>
                            <p>{blog?.samdestwo}</p>
                            <h3 className="fw-bolder">Why you should Purchase Browzwear: </h3>
                            <p>{blog?.samdesthree}</p>
                            {blog?.benifit &&
                                blog?.benifit?.map((d) => <li>{d.benifit}</li>)}
                            {blog?.firstDes &&
                                <p className="my-3">{blog?.bellow}</p>}
                            <p>If you’re ready to explore the world of 3D fashion design and achieve your creative potential, schedule a demo to see Browzwear’s 3D fashion design software in action. </p>
                        </>
                    }
                </div>
                <h6 className="fw-bold">If You can be of any further services to you, please feel free to contact me. Thanks for your patience & attention.</h6>
                <Link className="text-danger fw-bolder" to='/blog'>Go to Main Blog</Link>
            </div>
        </>
    )
}
export default BlogDetails