import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loading from '../../../../Loading/Loading';
import UserInfoRow from './UserInfoRow';

const UserInfo = () => {
     //use react query
const {data:userss,isLoading,refetch}=useQuery(('userss'),()=> fetch(`https://cutsew-server.onrender.com/user`)
.then(res=>res.json()));
if(isLoading){
    <Loading></Loading>
}
    return (
        <div>
              <div className="table-responsive">
                <table className="table table-striped table-dark table-bordered " >
                    <thead>
                        <tr>
                            <th scope="col">SL.NO</th>
                            <th scope="col">Name/Email</th>
                            <th scope="col">Number</th>
                            <th scope="col">companyName</th>
                            <th scope="col">Dsignation</th>
                            <th scope="col">Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userss?.map((user,index) => <UserInfoRow
                                    key={user._id}
                                    index={index+1}
                                    user={user}
                                    refetch={refetch}
                                ></UserInfoRow>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserInfo;