import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';


const useAdmin = () => {
    const [admin,setAdmin]=useState(false)
    const [user] = useAuthState(auth);
    useEffect(()=>{
        const email=user?.email
    
        fetch(`https://cutsew-server.onrender.com/admin?email=${email}`,{
            method:"GET",
            headers:{
                'content-type':'application/json'
            }
        })
        .then(res=>res.json())
        .then(data=>{
            setAdmin(data.admin)
           
        })
   
    },[])

    return[admin];
    
};

export default useAdmin;