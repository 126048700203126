import React from 'react';
import { Helmet } from 'react-helmet';
import { MetaTags } from 'react-meta-tags';
import BrandSlide from './BrandSlide/BrandSlide';
import ChoseUs from './ChoseUs/ChoseUs';
import Hero from './Hero/Hero';
import Map from './Map/Map';
import Products from './Products/Products';
import Service from './Service/Service';
import TKTPlotter from '../../Assets/home/TKT Plotter.png'

const Home = () => {

    <Helmet>
        <title>TKT Plotter</title>
        <meta id="title" property="title" content="TKT Plotter" />
        <meta id="og-title" property="og:title" content="TKT Plotter" />
        <meta property="og:image" content="../../../Assets/home/TKT Plotter.png" />
        <meta property="image" content='../../../Assets/home/TKT Plotter.png' />
        <img src={TKTPlotter} />
        <meta name="keywords"
            content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"></meta>
        <meta name="description" content="TKT, TKT Plotter, Spanish Plotter, Plotter, China Plotter, 3D CAD, 3D Garments CAD, 3D, Browzwear, VStitcher, Browzwear 3D, VStitcher 3D"/>
    </Helmet>
    return (
        <div>
            <Hero></Hero>
            <ChoseUs></ChoseUs>
            <Service></Service>
            <Products></Products>
            <BrandSlide></BrandSlide>
            <Map></Map>
        </div>
    );
};
export default Home;