import React from 'react';
import './BrandSlide.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/autoplay";
import 'swiper/css/parallax';

import slide1 from '../../../Assets/home/slide-brand/Asset 10.png';
import slide2 from '../../../Assets/home/slide-brand/Asset 11.png';
import slide3 from '../../../Assets/home/slide-brand/Asset 12.png';
import slide4 from '../../../Assets/home/slide-brand/Asset_3.png';
import slide5 from '../../../Assets/home/slide-brand/Asset 4.png';
import slide7 from '../../../Assets/home/slide-brand/Asset 7.png';
import slide8 from '../../../Assets/home/slide-brand/Asset 8.png';
import slide9 from '../../../Assets/home/slide-brand/Asset 9.png';

const BrandSlide = () => {
    return (
        <div className='brandSlide-section'>
            <div className="container">
                <div className="title pb-2">
                    <h2 className='fw-bold'>we represent</h2>
                    <div className='title-line '>
                        <div className='title-border '>
                            <div className='title-border-inner'></div>
                            <div className='title-border-inner'></div>
                            <div className='title-border-inner'></div>
                            <div className='title-border-inner'></div>
                        </div>
                    </div>
                </div>
                <Swiper
                    // install Swiper modules
                    modules={[Autoplay, A11y]}
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={55}
                    slidesPerView={5}
                    loop={true}

                    breakpoints={{
                        // when window width is >= 400px
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        // when window width is >= 360px
                        360: {
                            width: 360,
                            slidesPerView: 1,
                        },
                        // when window width is >= 640px
                        640: {
                            width: 640,
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 3,
                        },
                        // when window width is >= 960px
                        960: {
                            width: 960,
                            slidesPerView: 4,
                        },
                        // when window width is >= 1200px
                        1200: {
                            width: 1200,
                            slidesPerView: 5,
                        },
                    }}
                >
                    {/* -----------------slider-1------------ */}
                    <SwiperSlide >
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide1} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-2-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide2} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-3-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide3} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-4-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide4} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-5-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide5} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-7-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide7} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-8-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide8} alt="TKT" />
                        </div>
                    </SwiperSlide>
                    {/* -------------slider-9-------------- */}
                    <SwiperSlide>
                        <div className="brandSlide ">
                            <img className="slide-img" src={slide9} alt="TKT" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default BrandSlide;