import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../../../../firebase.init";


const UpdateStatus = () => {
    const [imageFiles, setImageFiles] = useState([]);
    //*************get image url****************
    const handleImageChange = (event) => {
        const files = event.target.files;
        setImageFiles(files);
    };
    const { id } = useParams()
    const handleDateUpdate = async (e) => {
        e.preventDefault();
        const imageUrls = await Promise.all(Array.from(imageFiles).map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'mboyi3wd'); // Replace with your upload preset name
            formData.append('cloud_name', 'drmbkgue0'); // Replace with your Cloudinary cloud name
            const response = await fetch('https://api.cloudinary.com/v1_1/drmbkgue0/image/upload', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            return data.secure_url;
        }));
        const updateStatus = e.target.status.value;
        const updateDescription = e.target.description.value;
        fetch(`https://cutsew-server.onrender.com/updateStatus/${id}`,
            {
                method: "PUT",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ updateStatus, updateDescription,imageUrls })
            }
        )
            .then(res => res.json())
            .then(data => {
                if (data) {
                    toast('Problems added successfully');
                } else {
                    toast.error('Problemsadded unsuccessfully')
                }
            })
    }
    return (
        <div className="text-center bg-light">
            < div className="container py-3">
                <h1 className="text-uppercase fw-bolder">Update Your Status</h1>
                <form className='mt-3' onSubmit={handleDateUpdate}>
                    <input name="status" type="text" placeholder='Update status' className="form-control" value="solved" readOnly />
                    <textarea name="description" type="text" placeholder='Update Dsescription' className="form-control my-3" rows="5" required />
                    <input type="file" name="images" className="form-control my-3" multiple onChange={handleImageChange} />
                    <input className='signup-btn mb-3' type="submit" value="Update" />
                </form>
            </div>
        </div>
    )
}
export default UpdateStatus