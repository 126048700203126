import React, { } from 'react';
import {  useQuery } from 'react-query';
import Loading from '../../../../Loading/Loading';
import UsersRow from './UsersRow';

const User = () => {
 //use react query
const {data:userss,isLoading,refetch}=useQuery(('userss'),()=> fetch(`https://cutsew-server.onrender.com/user`)
 .then(res=>res.json()));
 if(isLoading){
     return<Loading></Loading>
 }
    return (
        <div>
           <div className="table-responsive">
                <table className="table table-striped table-dark table-bordered " >
                    <thead>
                        <tr>
                            <th scope="col">SL.NO</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Ser.Admin</th>
                            <th scope="col">Admin</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userss?.map((user,index) => <UsersRow 
                                    key={user._id}
                                    index={index+1}
                                    user={user}
                                    refetch={refetch}
                                ></UsersRow>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default User;