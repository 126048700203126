import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Pages/Shared/Header/Header';
import Home from './Components/Pages/Home/Home';
import Footer from './Components/Pages/Shared/Footer/Footer';
import About from './Components/Pages/About/About';
import Products from './Components/Pages/Product/Product';
import Blog from './Components/Pages/Blog/Blog';
import Contact from './Components/Pages/Contact/Contact';
import Login from './Components/Pages/Shared/Login/Login';
import SignUp from './Components/Pages/Shared/SignUp/SignUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from './Components/Pages/Dashoard/Users/UsersRow/User';
import Dashboard from './Components/Pages/Dashoard/Users/Dashboard';
import UserInfo from './Components/Pages/Dashoard/Users/UserInfo/UserInfo';
import RequireAuth from './Components/RequireAuth/RequireAuth';
import Welcome from './Components/Pages/Dashoard/Users/Welcome/Welcome';
import NotFound from './Components/Pages/Shared/NotFound/NotFound';
import Whatsapp from './Components/Chat/Whatsapp/Whatsapp';
import { useEffect, useState } from 'react';
import PhoneSignIn from './Components/Pages/Shared/PhoneSignIn/PhoneSignIn';
import Loading from './Components/Loading/Loading';
import Facebook from './Components/Chat/Facebook/Facebook';
import AddProducts from './Components/Pages/Dashoard/AddProducts/AddProducts';
import { Helmet } from 'react-helmet';
import ProductDetails from './Components/Pages/Product/ProductDetails/ProductDetails';
import BlogDetails from './Components/Pages/Blog/BlogDetails/BlogDetails';
import AddProblems from './Components/Pages/Dashoard/ServiceAdmin/AddProblems/AddProblems';
import GetProblems from './Components/Pages/Dashoard/ServiceAdmin/GetProblems/GetProblems';
import UpdateStatus from './Components/Pages/Dashoard/ServiceAdmin/UpdateStatus/UpdateStatus';
import ServiceDetails from './Components/Pages/Dashoard/ServiceAdmin/ProblemDetails/ServiceDetails';
import AllProblems from './Components/Pages/Dashoard/ServiceAdmin/AllProblems/AllProblems';




function App() {
  const [showLoading, setShowLoading] = useState(true)
  // document.addEventListener('contextmenu', (e) => e.preventDefault());
  // document.onkeydown = function(e) {
  //   if(e.keyCode === 123) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.keyCode ==='U'.charCodeAt(0)) {
  //      return false;
  //   }
  //     if(e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
  //      return false;
  //   }
  // }
  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false)
    }, 200);
  }, []);

  return (
    <div>


      {
        showLoading ?
          <Loading></Loading>
          :
          <div>
            <Header></Header>

            <Whatsapp></Whatsapp>


            <Routes>
              <Route path="/" element={<Home></Home>} />
              <Route path="/about" element={<About></About>} />
              <Route path="/product" element={<Products></Products>} />
              <Route path="/productDetails/:id" element={<ProductDetails></ProductDetails>} />
              <Route path="/blog" element={<Blog></Blog>} />
              <Route path="/blog/blogdetails/:id" element={<BlogDetails></BlogDetails>} />
              <Route path="/contact" element={<Contact></Contact>} />
              <Route path="/login" element={<Login></Login>} />
              <Route path="/signup" element={<SignUp></SignUp>} />
              <Route path="/dashboard" element={<RequireAuth>
                <Dashboard />
              </RequireAuth>}>
                <Route index element={<Welcome></Welcome>} ></Route>
                <Route path="us9er9s9" element={<User></User>} />
                <Route path="userinfoC57c" element={<UserInfo />} />
                <Route path="productscut75" element={<AddProducts />} />
                <Route path="servic5=cut=add=problems" element={<AddProblems />} />
                <Route path="problems=cut=all=825" element={<GetProblems />} />
                <Route path="problAllems=cut=all=735" element={<AllProblems />} />
              </Route>
              <Route path="/updateStatus/:id" element={< UpdateStatus/>} />
              <Route path="/problemsDetails/:id" element={<ServiceDetails/>} />
              <Route path="/phonesignIn" element={<PhoneSignIn></PhoneSignIn>} />
              <Route path="*" element={<NotFound></NotFound>} />
            </Routes>
  
            <Footer></Footer>

            <ToastContainer />
            <Facebook></Facebook>
          </div>
      }
    </div>
  );
}

export default App;
