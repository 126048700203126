import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../../firebase.init';

const Welcome = () => {
    const [user]=useAuthState(auth)
    return (
        <div style={{height:'60vh'}}>
            <h1 className=''>Welcome {user?.displayName}</h1>
        </div>
    );
};

export default Welcome;