import React from "react"
import { Link } from "react-router-dom"
import './Blog.css'
const BlogDetails = ({ blog }) => {
    const { id, image, name, description } = blog
    const link = `blogdetails/${id}`
    return (
        <>
            <div className="col-lg-4 col-md-6 col-12">
                <div className="blog">
                    <img  src={image} alt="TKT" loading="lazy"/>
                    <div className="p-3 blog-content">
                        <Link className="blog-title" to={link} >{name}</Link>
                        <p className="my-2">{description?.slice(0, 110)}....</p>
                        <div>
                            <Link to={link} className="text-danger fw-bold  text-decoration-none">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogDetails