import React from "react";
import { useState } from "react";
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from "react-toastify";


const AddProducts = () => {
  const [image, setImage] = useState('')
  // ************AppendInput***********
  const [inputList, setInputList] = useState([{ descriptions2: "" }]);
  // ************handle input change****************
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // ************handle click event of the Remove button*************
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // **********handle click event of the Add button**********
  const handleAddClick = () => {
    setInputList([...inputList, { descriptions2: "" }]);
  };

  //*************get image url****************
  const uploadImage = async e => {
    setInputList([...inputList, { descriptions2: "" }]);
    e.preventDefault()
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'bxjbxv9v')

    const res = await fetch(
      '	https://api.cloudinary.com/v1_1/drmbkgue0/image/upload',
      {
        method: 'POST',
        body: data
      }
    )
    const file = await res.json()

    setImage(file.secure_url)
  }

  //**************others info for backend********************
  const { control, register, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "descriptions",
  });


  // Submit your data into Redux store
  const onSubmit = async data => {
    const product = {
      ProductName: data.productName,
      picture: image,
      productPrice: data.productPrice,
      productDescription: data.productDescription,
      productCategory: data.productCategory,
      descriptions: data.Descriptions,
      productTitle: data.productTitle,
      productTitle2: data.productTitle2,
      descriptions2:inputList
    }
    //send to your database
    fetch('https://cutsew-server.onrender.com/addsProducts', {
      method: "POST",
      headers: {
        'content-type': "application/json",
      },

      body: JSON.stringify(product)
    })
      .then(res => res.json())
      .then(output => {
        if (output.insertedId) {
          toast('Product added successfully');
        } else {
          toast.error('Product added unsuccessfully')
        }
      })

  };
  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* ----------Product Name---------- */}
        <div className="mb-2=3">
          <label>Product Name</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="productName" {...register("productName")} />
          </div>
        </div>
        {/* ----------Product Price---------- */}
        <div className="mb-3">
          <label>Product Price</label>
          <div className='input-group'>
            <input type="text" className="form-control" placeholder="productPrice" {...register("productPrice")} />
          </div>
        </div>
        {/* ----------Product Description---------- */}
        <div className="mb-3">
          <label>Product Description</label>
          <div className='input-group'>
            <textarea type="text" className="form-control" placeholder="productDescription" {...register("productDescription")} />
          </div>
        </div>
        {/* ----------***More Description*****------------ */}
        <h6>Title</h6>
        <input type="text" className="form-control my-3" placeholder="productTitle" {...register("productTitle")} />
        <label>Add More Description</label>
        {fields.map(({ id, descriptions }, index) => (
          <div key={id}>
            <textarea type="text" className="form-control"
              {...register(`Descriptions[${index}].descriptions`)}
              placeholder="Description"
              defaultValue={descriptions}
            />
            <button className="my-2 btn btn-secondary rounded-pill" type="button" onClick={() => remove(index)}>
              Remove
            </button>
          </div>
        ))}
        <div className="mt-2 mb-3">
          <button className="btn fw-bold shadow-lg bg-warning rounded-pill" type="button" onClick={() => append({})}>
            Add
          </button>
        </div>
        {/* ----------***More Description-2*****------------ */}
        <h6>Title2</h6>
        <input type="text" className="form-control my-3" placeholder="productTitle2" {...register("productTitle2")} />
        <label>Add More Description</label>
        {inputList.map((x, i,id) => {
          return (
            <div className="box">
              <input
               key={id}
                name="descriptions2"
                placeholder="Description"
                value={x.descriptions2}
                onChange={e => handleInputChange(e, i)}
                className="form-control"
              />
              <div>
                {inputList.length !== 1 && <button
                  onClick={() => handleRemoveClick(i)} className="my-2 btn btn-secondary rounded-pill">Remove</button>}
                {inputList.length - 1 === i && <button onClick={handleAddClick}className="btn fw-bold shadow-lg bg-warning rounded-pill my-2">Add</button>}
              </div>
            </div>
          );
        })}
        {/* ----------Product Image---------- */}
        <div className="mb-3">
          <label>Product Image</label>
          <input
            type="file"
            name="file"
            placeholder="Upload an image"
            onChange={uploadImage}
          />
          <div>
            <img src={image} style={{ width: '300px' }} alt="TKT"/>
          </div>
        </div>
        {/* ----------Product Category---------- */}
        <div className="mb-3">
          <label>Product Category</label>
          <div className='input-group'>
            < input type="text" className="form-control" placeholder="productCategory" {...register("productCategory")} />
          </div>
        </div>
        <input className='signup-btn ms-0 mb-1 mt-2' type="submit" value="Add Products" />
      </form>
    </div>
  )
}
export default AddProducts