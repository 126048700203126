import React from 'react';
import './Loading.css'

const Loading = () => {
    return (
        <div className='loading-section'>
            <div>
                {/* <button className="btn btn-square loading "></button>
            <img className='img-fluid mx-auto d-block' src="https://i.pinimg.com/originals/f9/41/ae/f941ae9d16fd7d2957eea6e5b1100d1e.gif" alt="" /> */}

            </div>
            <div className="ring">Loading
                <span></span>
            </div>
        </div>
    );
};

export default Loading;