import React from 'react';
import './Service.css';
import service1 from '../../../Assets/home/service1.png';
import service2 from '../../../Assets/home/service2.png';
import service3 from '../../../Assets/home/service3.png';
import service4 from '../../../Assets/home/service4.png';
import service5 from '../../../Assets/home/service5.png';
import service6 from '../../../Assets/home/service6.png';
import Zoom from 'react-reveal/Zoom';

const Service = () => {
   return (
      <div>
         <div className="service">
            <div className="container">
               <div className="row ">
                  <div className="col-md-8 offset-md-2">
                     <div className="title ">
                        <h2>Service <strong className="black">Process</strong></h2>
                        <div className='title-line pb-1 '>
                           <div className='title-border'>
                              <div className='title-border-inner'></div>
                              <div className='title-border-inner'></div>
                              <div className='title-border-inner'></div>
                              <div className='title-border-inner'></div>
                           </div>
                        </div>
                        <span>Fastest, Easy and effective way to get your desired service.</span>
                     </div>
                  </div>
               </div>
               <div className="row gx-4 gy-5">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service1} alt="TKT" />
                           </div>
                           <h3>Fast service</h3>
                           <p className="justify-text">We always try to provide any service within short time, after client's
                              confirmation. </p>
                        </div>
                     </Zoom>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service2} alt="TKT" />
                           </div>
                           <h3>Easy payments</h3>
                           <p className="justify-text">We accept comfortable instalment, if the client's payment history is Good.
                           </p>
                        </div>
                     </Zoom>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6  col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service3} alt="TKT" />
                           </div>
                           <h3>Expert team</h3>
                           <p className="justify-text">Our expert service team can identify the error easily with their
                              experience, which will save "your time=money". </p>
                        </div>
                     </Zoom>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service4} alt="TKT" />
                           </div>
                           <h3>Affordable services</h3>
                           <p className="justify-text">We provide yearly, half yearly and on call service as per client's needs,
                              Even we have some package service. </p>
                        </div>
                     </Zoom>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service5} alt="TKT" />
                           </div>
                           <h3>90 Days warranty</h3>
                           <p className="justify-text">We always provide 90 days warranty for any electronic parts, to ensure our parts quality. if not burned or physical damage. </p>
                        </div>
                     </Zoom>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                     <Zoom>
                        <div className="service-box h-100">
                           <div className='img-wrapper'>
                              <img src={service6} alt="TKT" />
                           </div>
                           <h3>Award winning</h3>
                           <p className="justify-text">We gain many new service contact every year from many more factories for on time and good quality service. </p>
                        </div>
                     </Zoom>
                  </div>
               </div>
            </div>
         </div>
      </div >
   );
};

export default Service;